<!--  -->
<template>
<div class="filePage">
    <div class="protocol-head">
        <div class="protocol-head-container flex-vc flex">
            <a href="/" class="flex-vc flex">
                <div class="logo">
                    <img src="../../assets/img/default/upupoo.png">
                </div>
                <span class="text">upupoo</span>
            </a>
        </div>
    </div>
    <div class="protocol-layout-body">
        <h3>《UPUPOO动态壁纸桌面-上传规则》</h3>
        <p class="protocal-title-bold">一、壁纸上传</p>
        <p class="protocal-title-bold">（一）格式说明</p>
        <p>1. 视频壁纸：仅支持MP4格式，大小不得超过500MB。</p>
        <p>2. 图片壁纸：仅支持JPG格式。</p>
        <p>3. 网页壁纸：仅支持ZIP格式，需将HTML等文件打包后上传。</p>
        <br>
        <p class="protocal-title-bold">（二）审核说明</p>
        <p>1. 上传后出现【待审核】，表示已上传成功，耐心等待审核通过即可。</p>
        <p>2. 工作日壁纸于24小时内审核完毕；节假日无审核人员在线，于工作日统一处理。</p>
        <br>
        <p class="protocal-title-bold">（三）未过审说明</p>
        <p>（1）格式不合规。</p>
        <p>a 尺寸为竖屏壁纸；</p>
        <p>b 分辨率低于1920*1080px；</p>
        <p>c 标题为数字或乱码字母，无法通过关键词查找；</p>
        <p>d 壁纸内容涉及色情、暴力、政治等违规因素。</p>
        <p>（2） 影响正常使用。</p>
        <p>a 壁纸黑边未去除；</p>
        <p>b 壁纸存在其他平台水印； </p>
        <p>c 壁纸上有私人信息或其他遮挡物。</p>
        <p>（3）重复上传/平台已存在该壁纸。</p>
        <p>*如壁纸未通过审核，可在我的- 本地壁纸- 更多操作中选择本地使用。</p>
        <br>
        <p class="protocal-title-bold">（四）付费壁纸说明</p>
        <p>集市仅支持售卖原创资源。</p>
        <p>a 如上传自己制作的动画，请提供动画制作工程截图；</p>
        <p>b 如上传自己拍摄的图片或视频，请提供相机电子屏翻拍；</p>
        <p>c 如上传自己绘画的图片，请提供PSD分层清晰截图。</p>
        <br>
        <p class="protocal-title-bold">二、美化上传</p>
        <p class="protocal-title-bold">（一）格式说明</p>
        <p>1. 鼠标文件：仅支持ANI、CUR格式，尺寸不得低于64*64px。</p>
        <p>2. 鼠标封面：仅支持JPG格式，尺寸为296*167px，大小不得超过300KB。</p>
        <p>3. 任务栏：仅支持PNG格式，横向背景大小为1920*40px，竖向背景大小为62*1080px。</p>
        <p>4. 任务栏封面：仅支持JPG格式，尺寸为346*195px，大小不得超过300KB。</p>
        <br>
        <p class="protocal-title-bold">（二）审核说明</p>
        <p>1. 上传后出现【待审核】，表示已上传成功，耐心等待审核通过即可。</p>
        <p>2. 工作日美化资源于24小时内审核完毕；节假日无审核人员在线，于工作日统一处理。</p>
        <br>
        <p class="protocal-title-bold">（三）未过审说明</p>
        <p>1. 平台已存在该美化资源。</p>
        <p>2. 上传资源为电脑自带美化。</p>
        <br>
        <p class="protocal-title-bold">（四）付费美化说明</p>
        <p>集市仅支持售卖原创美化资源，请提供分层清晰截图或工程制作截图。</p>
        <br>
        <p class="protocal-title-bold">三、上传问题</p>
        <p>上传时如遇“无法继续操作”提示，原因如下：</p>
        <p>1、请检查磁盘空间是否足够；</p>
        <p>2、请检查是否已开启管理员权限；</p>
        <p>如以上两种都不是，请在客户端右上角设置中联系客服处理。</p>
        <br>
        <p class="protocal-title-bold">四、补充说明</p>
        <p>此上传入口为用户上传共享资源通道，若上传仅供自己使用资源，请在“我的”-“本地壁纸”-“更多操作”-“本地打开”处使用该功能。所有上传资源不得从事任何包括但不限于以下违反法规的行为，也不得为以下违反法规的行为提供便利：</p>
        <p>（1）反对宪法所确定的基本原则的行为；</p>
        <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的行为；</p>
        <p>（3）损坏国家荣誉和利益的行为；</p>
        <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的行为；</p>
        <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的行为；</p>
        <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的行为；</p>
        <p>（7）散步淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的行为；</p>
        <p>（8）侮辱或者诽谤他人，侵害他人合法权益的行为；</p>
        <p>（9）侵害他人知识产权、商业秘密等合法权利的行为；</p>
        <p>（10）恶意虚构实施、隐瞒真相以误导、欺骗他人的行为；</p>
        <p>（11）发布、传送、传播广告信息及垃圾信息；</p>
        <p>（12）其他法律法规禁止的行为。</p>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
